import axios, { type AxiosError, type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from "axios";
import queryString, { type StringifiableRecord } from "query-string";

import csrfFactory from "@core/csrf/csrfFactory";

import ApiException from "./ApiException";

interface ErrorApiResponse {
    errorCode: string | number;
}

const axiosDefaultConfiguration: AxiosRequestConfig<unknown> = {
    timeout: 0,
    withCredentials: false,
    headers: {
        "X-Requested-With": "XMLHttpRequest",
        "pragma": "no-cache",
        "cache-control": "no-cache"
    }
};

const axiosDefaultAuthenticationConfiguration: AxiosRequestConfig<unknown> = {
    timeout: 0,
    withCredentials: true,
    headers: {
        "X-Requested-With": "XMLHttpRequest",
        "pragma": "no-cache",
        "cache-control": "no-cache"
    }
};

const isAxiosError = (error: unknown): error is AxiosError => typeof error === "object" && !!error && "response" in error;
const isErrorResponse = (data: unknown): data is ErrorApiResponse => typeof data == "object" && !!data && "errorCode" in data;

async function performAsync<T>(apiPromise: () => Promise<AxiosResponse<T>>) {
    try {
        const response = await apiPromise();

        return response.data;
    } catch (error) {
        if (!isAxiosError(error) || !error.response) {
            throw error;
        }

        const response = error.response;

        if (response.status === 400 && isErrorResponse(response.data)) {
            throw new ApiException(response.data.errorCode, response);
        } else {
            throw new ApiException(response.status, response);
        }
    }
}

export const buildClient = (baseUrl: string, clientConfiguration?: AxiosRequestConfig<unknown>, configure?: (client: AxiosInstance) => AxiosInstance) => {
    const axiosConfiguration: AxiosRequestConfig<unknown> = {
        ...axiosDefaultConfiguration,
        ...clientConfiguration
    };

    let client = axios.create(axiosConfiguration);

    client.interceptors.request.use(csrfFactory.injectCSRFToken.bind(csrfFactory));

    if (configure) {
        client = configure(client);
    }

    return {
        getAsync: <T>(relativeUrl: string, query: StringifiableRecord = {}, configuration: AxiosRequestConfig = {}) => {
            const url = queryString.stringifyUrl({
                url: relativeUrl,
                query
            }, {
                skipNull: true,
                skipEmptyString: true
            });

            return performAsync(() => client.get<T>(`${baseUrl}${url}`, configuration));
        },
        postAsync: <T, D>(relativeUrl: string, body?: D, configuration?: AxiosRequestConfig<D>) => {
            return performAsync(() =>
                client.post<T, AxiosResponse<T>, D>(`${baseUrl}${relativeUrl}`, body ?? {} as D, configuration ?? {})
            );
        },
        putAsync: <T, D>(relativeUrl: string, body?: D, configuration?: AxiosRequestConfig<D>) => {
            return performAsync(
                () => client.put<T, AxiosResponse<T>, D>(`${baseUrl}${relativeUrl}`, body ?? {} as D, configuration)
            );
        },
        patchAsync: <T, D>(relativeUrl: string, body?: D, configuration?: AxiosRequestConfig<D>) => {
            return performAsync(
                () => client.patch<T, AxiosResponse<T>, D>(`${baseUrl}${relativeUrl}`, body ?? {} as D, configuration ?? {})
            );
        },
        deleteAsync: <T>(relativeUrl: string, configuration?: AxiosRequestConfig) => {
            return performAsync(() => client.delete<T>(`${baseUrl}${relativeUrl}`, configuration ?? {}));
        }
    };
};

export const buildLoginClient = (baseUrl: string, clientConfiguration?: AxiosRequestConfig<unknown>, configure?: (client: AxiosInstance) => AxiosInstance) => {
    const axiosAuthenticationConfiguration = {
        ...axiosDefaultAuthenticationConfiguration,
        ...clientConfiguration
    };

    let loginClient = axios.create(axiosAuthenticationConfiguration);

    loginClient.interceptors.request.use(csrfFactory.injectCSRFToken.bind(csrfFactory));

    if (configure) {
        loginClient = configure(loginClient);
    }

    return {
        postLoginAsync: <T, D>(relativeUrl: string, body?: D, configuration?: AxiosRequestConfig<D>) => {
            return performAsync(
                () => loginClient.post<T, AxiosResponse<T>, D>(`${baseUrl}${relativeUrl}`, body, configuration)
            );
        }
    };
};

export type ApiClient = ReturnType<typeof buildClient>;
export type LoginClient = ReturnType<typeof buildLoginClient>;