enum Feature {
    useLogRocket = 4104,
    notificationUrlWithToken = 4096,
    pulseSurveyCustomTextualQuestions = 6152,
    multipleRecipientsGoodVibes = 6156,
    customSurveyPermissionAlerts = 9232,
    useWorkleapBrand = 12026
}

export default Feature;
