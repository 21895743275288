import React, { useState } from "react";

import type { GuidString } from "@/types/utilityTypes";
import { AngleLeftIcon, InfoIcon } from "@hopper-ui/icons";
import HelperText from "@igloo-ui/helper-text";
import type { TagItem } from "@igloo-ui/tag-picker";
import Textarea from "@igloo-ui/textarea";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";
import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import GhostButton from "@components/button/GhostButton";
import PrimaryButton from "@components/button/PrimaryButton";
import InlineLabeledFormField from "@components/form/InlineLabeledFormField";
import LabeledFormField from "@components/form/LabeledFormField";
import PeerSelect from "@components/peer-select/PeerSelect";
import TextArea from "@components/text-area/TextArea";
import VisibilityDisclaimer from "@components/visibility/VisibilityDisclaimer";

import type { ActivePrompt, UserIdentity } from "@api/RecognitionActivityApiClient";
import { trackButtonClicked } from "@core/tracking/track";
import TrackingPropertyName from "@core/tracking/TrackingPropertyName";
import * as ensure from "@core/utils/validation";

import RecognitionCardImage from "./recognition/RecognitionCardImage";
import RecognitionRecipientsTagPicker from "./recognition/RecognitionRecipientsTagPicker";
import RecognitionSender from "./recognition/RecognitionSender";

import "./recognition-activity-customize-message-step.scss";

const MAX_MESSAGE_LENGTH = 1500;
const MAX_RECIPIENTS = 25;

export interface RecognitionActivityCustomizeMessageStepProps {
    className?: string;
    correlationId: string;
    selectedPrompt: ActivePrompt;
    searchPeersAsync: (search: string, excludedIds?: string[]) => Promise<UserIdentity[]>;
    onSubmit: (recipientId: GuidString, message: string | null | undefined) => void;
    onSubmitMultipleRecipients: (recipientIds: GuidString[], message: string | null | undefined) => void;
    onCancel: () => void;
    onAnimationCompleted: () => void;
}

const RecognitionActivityCustomizeMessageStep = ({ className, correlationId, selectedPrompt, searchPeersAsync, onSubmit, onSubmitMultipleRecipients, onCancel, onAnimationCompleted }: RecognitionActivityCustomizeMessageStepProps) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);
    const isMultipleRecipientsGoodVibesEnabled = useIsFeatureEnabled(feature => feature.multipleRecipientsGoodVibes);

    const { t } = useTranslation("activities");

    const { isNetworkAdmin, isCompanyManager, isTeamManager } = useAuthenticatedUser();

    const [internalRecipientId, setInternalRecipientId] = useState<GuidString | null>(null);
    const [selectedRecipients, setSelectedRecipients] = useState<TagItem[]>([]);
    const [internalMessage, setInternalMessage] = useState("");

    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const validations = {
        recipientId: ensure.isNotNullOrEmpty(internalRecipientId, t("recognitionFrame.recipient-field-required-error"))
    };

    const validateRecipients = () => {
        return isMultipleRecipientsGoodVibesEnabled ? selectedRecipients.length > 0 : validations.recipientId.isValid;
    };

    const handleOnSubmit = () => {
        if (validateRecipients()) {
            setShowValidationErrors(false);

            if (isMultipleRecipientsGoodVibesEnabled) {
                const recipientIds = selectedRecipients.map(r => r.id!);
                onSubmitMultipleRecipients!(recipientIds, internalMessage.trim());
            } else {
                onSubmit(internalRecipientId!, internalMessage.trim());
            }
            onAnimationCompleted();
        } else {
            setShowValidationErrors(true);
        }
    };

    const handleOnBackToCardsClick = () => {
        trackButtonClicked(isNetworkAdmin, isCompanyManager, isTeamManager, "Recognition customize message", "Back to cards", { [TrackingPropertyName.CorrelationId]: correlationId });

        onCancel();
    };

    const handleOnCancelClick = () => {
        trackButtonClicked(isNetworkAdmin, isCompanyManager, isTeamManager, "Recognition customize message", "Cancel", { [TrackingPropertyName.CorrelationId]: correlationId });

        onCancel();
    };

    const handleOnAddRecipient = (recipient: TagItem) => {
        setShowValidationErrors(false);
        setSelectedRecipients([...selectedRecipients, recipient]);
    };

    const handleOnRemoveRecipient = (recipientId: string) => {
        setSelectedRecipients(selectedRecipients.filter(r => r.id !== recipientId));
    };

    const messageFieldLabel = (
        <div className="recognition-activity-customize-message-step__form-label">
            <Trans ns="activities"
                i18nKey="recognitionFrame.message-field-label"
                components={[<span key={0} className="recognition-activity-customize-message-step__optional-label">REPLACED BY I18N</span>]}
            />
        </div>
    );

    const visibilityDisclaimerClasses = isMultipleRecipientsGoodVibesEnabled ? "recognition-activity-customize-message-step__visibility-disclaimer-multiple-recipients" : "recognition-activity-customize-message-step__visibility-disclaimer";

    const classes = classNames(
        "recognition-activity-customize-message-step",
        className
    );

    return (
        <div className={classes}>
            <GhostButton className="recognition-activity-customize-message-step__back-button"
                theme={isWorkleapBrandEnabled ? GhostButton.Theme.Dark : GhostButton.Theme.Light}
                iconLeading={<AngleLeftIcon size="md" />}
                onClick={handleOnBackToCardsClick}
            >
                {t("recognitionFrame.back-to-cards-button-label")}
            </GhostButton>
            <div className="recognition-activity-customize-message-step__card">
                <div className="recognition-activity-customize-message-step__prompt-section">
                    <RecognitionCardImage className="recognition-activity-customize-message-step__image" selectedPrompt={selectedPrompt} />
                </div>
                <div className="recognition-activity-customize-message-step__message-section">
                    <div className="recognition-activity-customize-message-step__form">
                        {isMultipleRecipientsGoodVibesEnabled
                            ? <div>
                                <div className="recognition-activity-customize-message-step__multiple-recipients-label">
                                    <div className="recognition-activity-customize-message-step__multiple-recipients-inline-label">
                                        {t("recognitionFrame.recipient-field-label")}
                                    </div>
                                    {selectedRecipients.length > 0 &&
                                        <div className="recognition-activity-customize-message-step__recipient-count-label">
                                            {t("recognitionFrame.recipientCount", { count: selectedRecipients.length })}
                                        </div>
                                    }

                                </div>
                                <RecognitionRecipientsTagPicker
                                    className="recognition-activity-customize-message-step__multiple-recipients-field"
                                    selectedRecipients={selectedRecipients}
                                    searchPeersAsync={searchPeersAsync}
                                    onRecipientsAdded={handleOnAddRecipient}
                                    onRecipientsRemoved={handleOnRemoveRecipient}
                                    displayError={showValidationErrors}
                                    maxRecipients={MAX_RECIPIENTS}
                                    placeholder={t("recognitionFrame.recipient-field-search-placeholder-multiple-recipients")}
                                    noResultsText={t("recognitionFrame.recipient-field-empty-state-label")}
                                />
                                {selectedRecipients.length >= MAX_RECIPIENTS && (
                                    <HelperText className="recognition-activity-customize-message-step__multiple-recipients-helper-text">
                                        <InfoIcon className="recognition-activity-customize-message-step__multiple-recipients-helper-text-icon" size="sm" />
                                        {t("recognitionFrame.recipient-limit-reached-label")}
                                    </HelperText>
                                )}
                            </div>
                            : <InlineLabeledFormField className="recognition-activity-customize-message-step__recipient-field" label={<div className="recognition-activity-customize-message-step__form-inline-label">{t("recognitionFrame.recipient-field-label")}</div>} htmlFor="peer-select-input">
                                <PeerSelect
                                    id="peer-select-input"
                                    placeholder={t("recognitionFrame.recipient-field-search-placeholder")}
                                    noResultsLabel={t("recognitionFrame.recipient-field-empty-state-label")}
                                    searchPeersAsync={searchPeersAsync}
                                    onChange={r => setInternalRecipientId(r)}
                                    error={showValidationErrors && !validations.recipientId.isValid}
                                />
                            </InlineLabeledFormField>
                        }
                        {!isWorkleapBrandEnabled &&
                            <InlineLabeledFormField className="recognition-activity-customize-message-step__sender-field" label={<div className="recognition-activity-customize-message-step__form-inline-label">{t("recognitionFrame.sender-field-label")}</div>}>
                                <RecognitionSender />
                            </InlineLabeledFormField>
                        }
                        <LabeledFormField className="recognition-activity-customize-message-step__message-field" label={messageFieldLabel} htmlFor="message-input">
                            {isWorkleapBrandEnabled
                                ? <Textarea id="message-input"
                                    className="recognition-activity-customize-message-step__message-input"
                                    value={internalMessage}
                                    placeholder={t("recognitionFrame.message-field-placeholder")}
                                    onChange={e => setInternalMessage(e.target.value)}
                                    maxLength={MAX_MESSAGE_LENGTH}
                                />
                                : <TextArea id="message-input"
                                    className="recognition-activity-customize-message-step__message-input"
                                    value={internalMessage}
                                    onChange={e => setInternalMessage(e.target.value)}
                                    maxLength={MAX_MESSAGE_LENGTH}
                                />}
                        </LabeledFormField>
                        <VisibilityDisclaimer className={visibilityDisclaimerClasses}>
                            {isMultipleRecipientsGoodVibesEnabled ? t("recognitionFrame.visibility-disclaimer-footer-label-multiple-recipients") : t("recognitionFrame.visibility-disclaimer-footer-label")}
                        </VisibilityDisclaimer>
                    </div>
                    <div className="recognition-activity-customize-message-step__call-to-actions">
                        <PrimaryButton onClick={handleOnSubmit}>
                            {t("recognitionFrame.send-button-label")}
                        </PrimaryButton>
                        <GhostButton onClick={handleOnCancelClick}>
                            {t("recognitionFrame.cancel-button-label")}
                        </GhostButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecognitionActivityCustomizeMessageStep;