import React from "react";

import type {
    BuiltActivity,
    OrchestratedActivity,
    OrchestratedStep
} from "@/types/activities/common";

import ActivityType from "@core/enums/ActivityType";
import CustomPollQuestionDisplayType from "@core/enums/CustomPollQuestionDisplayType";
import CustomSurveyStepType from "@core/enums/CustomPollStepType";
import OnboardingSurveyStepType from "@core/enums/OnboardingSurveyStepType";
import OutroType from "@core/enums/OutroType";
import PulseSurveyStepType from "@core/enums/PulseSurveyStepType";
import RecognitionCategory from "@core/enums/RecognitionCategory";
import StepType from "@core/enums/StepType";

import ActivitiesContext, {
    type useActivitiesContext
} from "./ActivitiesContext";
import buildActivitiesOrchestrator from "./ActivitiesOrchestrator";
import { useActivitiesServerSyncContext } from "./ActivitiesServerSyncContext";
import getBackgroundState from "./backgroundExpert";
import getBannerState from "./bannerExpert";
import getControlBarState from "./controlBarExpert";
import buildCustomPollActivityOrchestrator from "./CustomPollActivityOrchestrator";
import buildDeibSurveyActivityOrchestrator from "./DeibSurveyActivityOrchestrator";
import buildOnboardingSurveyActivityOrchestrator from "./OnboardingSurveyActivityOrchestrator";
import buildPulseSurveyActivityOrchestrator from "./PulseSurveyActivityOrchestrator";
import buildRecognitionActivityOrchestrator from "./RecognitionActivityOrchestrator";
import { searchPeers } from "./recognitionPeersGenerator";
import buildSmartQuestionActivityOrchestrator from "./SmartQuestionActivityOrchestrator";
import getCurrentTip from "./tipExpert";

function mapActivityToOrchestrator(
    activity: BuiltActivity,
    isFirstActivity: boolean,
    isLastActivity: boolean
): OrchestratedActivity {
    const { activityType } = activity;

    switch (activityType) {
        case ActivityType.PulseSurvey:
        case ActivityType.ContinuePulseSurvey:
        case ActivityType.TryPulseSurvey:
        case ActivityType.TestPulseSurveyQuestion:
            return buildPulseSurveyActivityOrchestrator(
                activity,
                isFirstActivity,
                isLastActivity
            );

        case ActivityType.CustomPoll:
        case ActivityType.PreviewCustomPoll:
        case ActivityType.TestCustomPoll:
            return buildCustomPollActivityOrchestrator(
                activity,
                isFirstActivity,
                isLastActivity
            );

        case ActivityType.SmartQuestion:
        case ActivityType.TestSmartQuestion:
            return buildSmartQuestionActivityOrchestrator(
                activity,
                isFirstActivity,
                isLastActivity
            );

        case ActivityType.Recognition:
        case ActivityType.TestRecognition:
            return buildRecognitionActivityOrchestrator(
                activity,
                isFirstActivity,
                isLastActivity
            );

        case ActivityType.OnboardingSurvey:
        case ActivityType.TestOnboardingSurvey:
        case ActivityType.ContinueOnboardingSurvey:
            return buildOnboardingSurveyActivityOrchestrator(
                activity,
                isFirstActivity,
                isLastActivity
            );

        case ActivityType.DeibSurvey:
        case ActivityType.TestDeibSurvey:
            return buildDeibSurveyActivityOrchestrator(
                activity,
                isFirstActivity,
                isLastActivity
            );

        default:
            throw new Error(`Activity Type (${activityType}) not supported`);
    }
}

interface Props {
    server: ReturnType<typeof useActivitiesServerSyncContext>;
    children: React.ReactNode;
}

interface State extends ReturnType<typeof useActivitiesContext> {
    _initializeAsync: (
        initializationFn: () => Promise<(BuiltActivity | null)[]>
    ) => Promise<void>;
    _setCurrentActivityState: (
        fn: (activity: OrchestratedActivity) => OrchestratedActivity
    ) => void;
    _setCurrentStepState: (fn: (step: OrchestratedStep) => OrchestratedStep) => void;
}

class ActivitiesContextProvider extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { server } = this.props;

        this.state = {
            isInitialized: false,
            isLoading: true,
            activities: null,
            orchestrator: null,
            outro: null,
            isCompleted: false,
            disableNextButton: false,
            initializeCompleteSurveyAsync:
                async isPulseSurveyCustomTextualQuestionsEnabled => {
                    const { _initializeAsync } = this.state;
                    await _initializeAsync(() =>
                        server.getCompleteSurveyActivitiesAsync(
                            isPulseSurveyCustomTextualQuestionsEnabled
                        )
                    );
                },
            initializeContinueSurveyAsync: async(
                questionId,
                answerScore,
                answerValue,
                isPulseSurveyCustomTextualQuestionsEnabled
            ) => {
                const { _initializeAsync } = this.state;
                await _initializeAsync(() =>
                    server.getContinueSurveyActivitiesAsync(
                        questionId,
                        answerScore,
                        answerValue,
                        isPulseSurveyCustomTextualQuestionsEnabled
                    )
                );
            },
            initializeTestDeibSurveyAsync: async questionId => {
                const { _initializeAsync } = this.state;
                await _initializeAsync(() =>
                    server.getTestDeibSurveyActivityAsync(questionId)
                );
            },
            initializeTryPulseSurveyAsync: async() => {
                const { _initializeAsync } = this.state;
                await _initializeAsync(() =>
                    server.getTryPulseSurveyActivitiesAsync()
                );
            },
            initializeCustomPollAsync: async customPollId => {
                const { _initializeAsync } = this.state;
                await _initializeAsync(() =>
                    server.getCustomPollActivitiesAsync(customPollId)
                );
            },
            initializePreviewCustomPollAsync: async customPollId => {
                const { _initializeAsync } = this.state;
                await _initializeAsync(() =>
                    server.getPreviewCustomPollActivityAsync(customPollId)
                );
            },
            initializeRecognitionAsync: async() => {
                const { _initializeAsync } = this.state;
                await _initializeAsync(() =>
                    server.getRecognitionActivityAsync()
                );
            },
            initializeOnboardingSurveyAsync: async() => {
                const { _initializeAsync } = this.state;
                await _initializeAsync(() =>
                    server.getOnboardingSurveyActivityAsync()
                );
            },
            initializeTestPulseSurveyQuestionAsync: async questionId => {
                const { _initializeAsync } = this.state;
                await _initializeAsync(() =>
                    server.getTestPulseSurveyQuestionActivitiesAsync(questionId)
                );
            },
            initializeTestCustomPollAsync: async() => {
                const { _initializeAsync } = this.state;
                await _initializeAsync(() =>
                    server.getTestCustomPollActivitiesAsync()
                );
            },
            initializeTestSmartQuestionAsync: async(metricId, subMetricId) => {
                const { _initializeAsync } = this.state;
                await _initializeAsync(() =>
                    server.getTestSmartQuestionActivitiesAsync(
                        metricId,
                        subMetricId
                    )
                );
            },
            initializeTestRecognitionAsync: async() => {
                const { _initializeAsync } = this.state;
                await _initializeAsync(() =>
                    server.getTestRecognitionActivityAsync()
                );
            },
            initializeTestOnboardingSurveyAsync: async questionId => {
                const { _initializeAsync } = this.state;
                await _initializeAsync(() =>
                    server.getTestOnboardingSurveyActivityAsync(questionId)
                );
            },
            _initializeAsync: async initializationFn => {
                const { isInitialized } = this.state;

                if (!isInitialized) {
                    this.setState({ isInitialized: true, isLoading: true });

                    const orchestrator = buildActivitiesOrchestrator();
                    const rawActivities = (await initializationFn()).filter(activity => activity !== null);

                    if (rawActivities.length === 0) {
                        this.setState({
                            activities: [],
                            orchestrator,
                            isLoading: false,
                            isCompleted: true,
                            outro: OutroType.PulseSurveyRedirect
                        });

                        return;
                    }

                    const activities = rawActivities.map((x, i) =>
                        mapActivityToOrchestrator(
                            x,
                            i === 0,
                            i === rawActivities.length - 1
                        )
                    );

                    this.setState({
                        activities,
                        orchestrator,
                        isLoading: false
                    });
                }
            },
            getCurrentActivity: () => {
                const { activities, orchestrator } = this.state;
                if (!activities || activities.length === 0 || !orchestrator) {
                    return null;
                }

                return activities[orchestrator.currentActivityIndex];
            },
            setDisableNextButton: disableNextButton => {
                this.setState(s => ({
                    ...s,
                    disableNextButton
                }));
            },
            setIsAnswered: isAnswered => {
                const { _setCurrentStepState } = this.state;
                _setCurrentStepState(step => ({
                    ...step,
                    isAnswered
                }));
            },
            shouldSaveAnswers: () => {
                const { getCurrentActivity } = this.state;
                const activityType = getCurrentActivity()?.activityType;

                switch (activityType) {
                    case ActivityType.ContinuePulseSurvey:
                    case ActivityType.PulseSurvey:
                    case ActivityType.CustomPoll:
                    case ActivityType.SmartQuestion:
                    case ActivityType.Recognition:
                    case ActivityType.OnboardingSurvey:
                    case ActivityType.ContinueOnboardingSurvey:
                    case ActivityType.DeibSurvey:
                        return true;

                    case ActivityType.TryPulseSurvey:
                    case ActivityType.TestPulseSurveyQuestion:
                    case ActivityType.PreviewCustomPoll:
                    case ActivityType.TestCustomPoll:
                    case ActivityType.TestSmartQuestion:
                    case ActivityType.TestRecognition:
                    case ActivityType.TestOnboardingSurvey:
                    case ActivityType.TestDeibSurvey:
                        return false;

                    default:
                        throw new Error(
                            `Activity Type (${activityType}) not supported`
                        );
                }
            },
            getPreviousStep: (delta = 1) => {
                const { getCurrentActivity } = this.state;
                const activity = getCurrentActivity();

                return activity && "steps" in activity
                    ? activity.steps[activity.currentStepIndex - delta]
                    : null;
            },
            getCurrentStep: () => {
                const { getCurrentActivity } = this.state;
                const activity = getCurrentActivity();

                return activity && "steps" in activity
                    ? activity.steps[activity.currentStepIndex]
                    : null;
            },
            getNextStep: () => {
                const { getCurrentActivity } = this.state;
                const activity = getCurrentActivity();

                return activity && "steps" in activity
                    ? activity.steps[activity.currentStepIndex + 1]
                    : null;
            },
            onGoToNextActivity: () => {
                this.setState(s => ({
                    ...s,
                    orchestrator: {
                        ...s.orchestrator,
                        currentActivityIndex:
                            (s.orchestrator?.currentActivityIndex ?? 0) + 1
                    }
                }));
            },
            onPulseSurveyQuestionAnswered: async(
                correlationId,
                questionId,
                answer,
                score
            ) => {
                const {
                    setDisableNextButton,
                    _setCurrentStepState,
                    shouldSaveAnswers
                } = this.state;
                setDisableNextButton(true);
                _setCurrentStepState(step => ({
                    ...step,
                    answer,
                    score,
                    isAnswered: true
                }));

                if (shouldSaveAnswers()) {
                    await server.upsertPulseSurveyAnswerAsync(
                        correlationId,
                        questionId,
                        score
                    );
                }
            },
            onPulseSurveyQuestionSkipped: async(
                correlationId,
                questionId,
                isFollowUpQuestion
            ) => {
                const { _setCurrentStepState, shouldSaveAnswers } = this.state;
                _setCurrentStepState(step => ({
                    ...step,
                    answer: null,
                    score: null,
                    feedback: "",
                    isAnonymous: true,
                    isAnswered: false
                }));

                if (shouldSaveAnswers()) {
                    if (isFollowUpQuestion) {
                        await server.upsertPulseSurveyFollowUpAnswerSkippedAsync(
                            correlationId,
                            questionId
                        );
                    } else {
                        await server.upsertPulseSurveyAnswerSkippedAsync(
                            correlationId,
                            questionId
                        );
                    }
                }
            },
            onPulseSurveyFeedbackAnonymityChanged: isAnonymous => {
                const { _setCurrentStepState } = this.state;
                _setCurrentStepState(step => ({
                    ...step,
                    isAnonymous
                }));
            },
            onPulseSurveyFollowUpAnswerLeft: async(
                correlationId,
                questionId,
                isConstructive,
                feedback,
                isAnonymous
            ) => {
                const {
                    setDisableNextButton,
                    _setCurrentStepState,
                    shouldSaveAnswers,
                    setIsAnswered
                } = this.state;
                setDisableNextButton(true);
                _setCurrentStepState(step => ({
                    ...step,
                    feedback,
                    isAnonymous
                }));

                if (shouldSaveAnswers()) {
                    if (feedback) {
                        setIsAnswered(true);
                        await server.upsertPulseSurveyFollowUpAnswerAsync(
                            correlationId,
                            questionId,
                            isConstructive,
                            feedback,
                            isAnonymous
                        );
                    } else {
                        setIsAnswered(false);
                        await server.deletePulseSurveyFollowUpAnswerAsync(
                            correlationId,
                            questionId
                        );
                    }
                }
            },
            onPulseSurveyCustomTextQuestionAnswered: async(
                correlationId,
                customQuestionId,
                feedback,
                isAnonymous
            ) => {
                const {
                    setDisableNextButton,
                    _setCurrentStepState,
                    shouldSaveAnswers,
                    setIsAnswered
                } = this.state;

                setDisableNextButton(true);
                _setCurrentStepState(step => ({
                    ...step,
                    feedback,
                    isAnonymous
                }));

                if (shouldSaveAnswers()) {
                    if (feedback) {
                        setIsAnswered(true);
                        await server.upsertPulseSurveyCustomTextAnswerAsync(correlationId, customQuestionId, { feedback, isAnonymous });
                    } else {
                        setIsAnswered(false);
                        await server.deletePulseSurveyCustomTextAnswerAsync(correlationId, customQuestionId);
                    }
                }
            },
            onPulseSurveyCustomQuestionSkipped: async(correlationId, customQuestionId) => {
                const { _setCurrentStepState, shouldSaveAnswers } = this.state;
                _setCurrentStepState(step => ({
                    ...step,
                    value: null,
                    feedback: null,
                    isAnonymous: true,
                    isAnswered: false
                }));

                if (shouldSaveAnswers()) {
                    await server.upsertPulseSurveyCustomQuestionAnswerSkippedAsync(correlationId, customQuestionId);
                }
            },
            onPulseSurveyActivityEnded: async correlationId => {
                const { shouldSaveAnswers } = this.state;
                if (shouldSaveAnswers()) {
                    await server.onPulseSurveyActivityEnded(correlationId);
                }
            },
            onCustomPollTextQuestionAnswered: async(
                customPollId,
                questionId,
                feedback,
                isAnonymous
            ) => {
                const {
                    setDisableNextButton,
                    _setCurrentStepState,
                    shouldSaveAnswers,
                    setIsAnswered
                } = this.state;
                setDisableNextButton(true);
                _setCurrentStepState(step => ({
                    ...step,
                    feedback,
                    isAnonymous
                }));

                if (shouldSaveAnswers()) {
                    if (feedback) {
                        setIsAnswered(true);
                        await server.upsertCustomPollAnswerAsync(
                            customPollId,
                            questionId,
                            { feedback, isAnonymous }
                        );
                    } else {
                        setIsAnswered(false);
                        await server.deleteCustomPollTextAnswerAsync(
                            customPollId,
                            questionId
                        );
                    }
                }
            },
            onCustomPollOpinionScaleQuestionAnswered: async(
                customPollId,
                questionId,
                score
            ) => {
                const {
                    setDisableNextButton,
                    _setCurrentStepState,
                    shouldSaveAnswers
                } = this.state;
                setDisableNextButton(true);
                _setCurrentStepState(step => ({
                    ...step,
                    value: score,
                    isAnswered: true
                }));

                if (shouldSaveAnswers()) {
                    await server.upsertCustomPollAnswerAsync(
                        customPollId,
                        questionId,
                        { value: score }
                    );
                }
            },
            onCustomPollMultipleChoiceQuestionAnswered: async(
                customPollId,
                questionId,
                choice,
                customAnswer
            ) => {
                const {
                    setDisableNextButton,
                    _setCurrentStepState,
                    shouldSaveAnswers
                } = this.state;
                setDisableNextButton(true);
                _setCurrentStepState(step =>
                    step.stepType === CustomSurveyStepType.AskQuestion
                    && step.displayType === CustomPollQuestionDisplayType.MultipleChoice ?
                        ({
                            ...step,
                            value: choice,
                            customAnswer,
                            isAnswered: true
                        })
                        : step);

                if (shouldSaveAnswers()) {
                    await server.upsertCustomPollAnswerAsync(
                        customPollId,
                        questionId,
                        { choices: [choice], customChoiceAnswer: customAnswer }
                    );
                }
            },
            onCustomPollMultipleChoiceQuestionWithMultiSelectionAnswered:
                async(
                    customPollId,
                    questionId,
                    choices,
                    customAnswer
                ) => {
                    const {
                        setDisableNextButton,
                        _setCurrentStepState,
                        shouldSaveAnswers
                    } = this.state;
                    setDisableNextButton(true);
                    _setCurrentStepState(step => ({
                        ...step,
                        selectedChoices: choices,
                        customAnswer,
                        isAnswered: true
                    }));

                    if (shouldSaveAnswers()) {
                        await server.upsertCustomPollAnswerAsync(
                            customPollId,
                            questionId,
                            { choices, customChoiceAnswer: customAnswer }
                        );
                    }
                },
            onCustomPollLikertScaleQuestionAnswered: async(
                customPollId,
                questionId,
                choice
            ) => {
                const {
                    setDisableNextButton,
                    _setCurrentStepState,
                    shouldSaveAnswers
                } = this.state;
                setDisableNextButton(true);
                _setCurrentStepState(step => ({
                    ...step,
                    value: choice,
                    isAnswered: true
                }));

                if (shouldSaveAnswers()) {
                    await server.upsertCustomPollAnswerAsync(
                        customPollId,
                        questionId,
                        { value: choice }
                    );
                }
            },
            onCustomPollQuestionSkipped: async(
                customPollId,
                questionId,
                isFollowUpQuestion
            ) => {
                const { _setCurrentStepState, shouldSaveAnswers } = this.state;
                _setCurrentStepState(step => ({
                    ...step,
                    value: null,
                    feedback: "",
                    isAnonymous: true,
                    isAnswered: false
                }));

                if (shouldSaveAnswers()) {
                    if (isFollowUpQuestion) {
                        await server.upsertCustomPollFollowUpAnswerSkippedAsync(
                            customPollId,
                            questionId
                        );
                    } else {
                        await server.upsertCustomPollAnswerSkippedAsync(
                            customPollId,
                            questionId
                        );
                    }
                }
            },
            onCustomPollFeedbackAnonymityChanged: isAnonymous => {
                const { _setCurrentStepState } = this.state;
                _setCurrentStepState(step => ({
                    ...step,
                    isAnonymous
                }));
            },
            onCustomPollFollowUpAnswerLeft: async(
                customPollId,
                questionId,
                feedback,
                isAnonymous
            ) => {
                const {
                    setDisableNextButton,
                    _setCurrentStepState,
                    shouldSaveAnswers,
                    setIsAnswered
                } = this.state;
                setDisableNextButton(true);
                _setCurrentStepState(step => ({
                    ...step,
                    feedback,
                    isAnonymous
                }));

                if (shouldSaveAnswers()) {
                    if (feedback) {
                        setIsAnswered(true);
                        await server.upsertCustomPollFollowUpAnswerAsync(
                            customPollId,
                            questionId,
                            feedback,
                            isAnonymous
                        );
                    } else {
                        setIsAnswered(false);
                        await server.deleteCustomPollFollowUpAnswerAsync(
                            customPollId,
                            questionId
                        );
                    }
                }
            },
            onCustomPollActivityEnded: async customPollId => {
                const { shouldSaveAnswers } = this.state;
                if (shouldSaveAnswers()) {
                    await server.onCustomPollActivityEnded(customPollId);
                }
            },
            onSmartQuestionSkipped: async subMetricId => {
                const { _setCurrentActivityState, shouldSaveAnswers } =
                    this.state;
                _setCurrentActivityState(activity => ({
                    ...activity,
                    feedback: null,
                    isAnonymous: true,
                    isAnswered: false
                }));

                if (shouldSaveAnswers()) {
                    await server.upsertSmartQuestionSkippedAsync(subMetricId);
                }
            },
            onSmartQuestionFeedbackAnonymityChanged: isAnonymous => {
                const { _setCurrentActivityState } = this.state;
                _setCurrentActivityState(activity => ({
                    ...activity,
                    isAnonymous
                }));
            },
            onSmartQuestionFeedbackLeft: async(
                subMetricId,
                feedback,
                isAnonymous
            ) => {
                const {
                    setDisableNextButton,
                    _setCurrentActivityState,
                    shouldSaveAnswers
                } = this.state;
                setDisableNextButton(true);
                _setCurrentActivityState(activity => ({
                    ...activity,
                    feedback,
                    isAnonymous,
                    isAnswered: true
                }));

                if (shouldSaveAnswers()) {
                    await server.upsertSmartQuestionFeedbackAsync(
                        subMetricId,
                        feedback,
                        isAnonymous
                    );
                }
            },
            onSmartQuestionActivityEnded: async subMetricId => {
                const { shouldSaveAnswers } = this.state;
                if (shouldSaveAnswers()) {
                    await server.onSmartQuestionActivityEnded(subMetricId);
                }
            },
            onRecognitionPromptSelected: (
                _,
                categoryId,
                promptId,
                promptIndex
            ) => {
                const { _setCurrentActivityState } = this.state;
                _setCurrentActivityState(activity => ({
                    ...activity,
                    promptId,
                    categoryId,
                    promptIndex
                }));
            },
            searchPeersAsync: async(query, excludedIds) => {
                const { getCurrentActivity } = this.state;
                const activityType = getCurrentActivity()?.activityType;

                if (activityType === ActivityType.TestRecognition) {
                    return searchPeers(query);
                }

                return await server.searchPeersAsync(query, excludedIds);
            },
            onRecognitionLeft: async(
                correlationId,
                recipientId,
                categoryId,
                promptId,
                message
            ) => {
                const { _setCurrentActivityState, shouldSaveAnswers } =
                    this.state;
                _setCurrentActivityState(activity => ({
                    ...activity,
                    recipientId,
                    categoryId,
                    promptId,
                    message,
                    isAnswered: true
                }));

                if (shouldSaveAnswers()) {
                    if (RecognitionCategory[categoryId]) {
                        await server.upsertOfficevibeRecognitionAsync(
                            correlationId,
                            recipientId,
                            categoryId,
                            promptId,
                            message
                        );
                    } else {
                        await server.upsertCustomRecognitionAsync(
                            correlationId,
                            recipientId,
                            categoryId,
                            promptId,
                            message
                        );
                    }
                }
            },
            onMultipleRecipientsRecognitionLeft: async(
                correlationId,
                recipientIds,
                categoryId,
                promptId,
                message
            ) => {
                const { _setCurrentActivityState, shouldSaveAnswers } = this.state;
                _setCurrentActivityState(activity => ({
                    ...activity,
                    recipientIds,
                    categoryId,
                    promptId,
                    message,
                    isAnswered: true
                }));

                if (shouldSaveAnswers()) {
                    if (RecognitionCategory[categoryId]) {
                        await server.upsertMultipleRecipientsOfficevibeRecognitionAsync(
                            correlationId,
                            recipientIds,
                            categoryId,
                            promptId,
                            message
                        );
                    } else {
                        await server.upsertMultipleRecipientsCustomRecognitionAsync(
                            correlationId,
                            recipientIds,
                            categoryId,
                            promptId,
                            message
                        );
                    }
                }
            },
            onRecognitionSkipped: async correlationId => {
                const { _setCurrentActivityState, shouldSaveAnswers } =
                    this.state;
                _setCurrentActivityState(activity => ({
                    ...activity,
                    recipientId: null,
                    message: null,
                    isAnswered: false
                }));

                if (shouldSaveAnswers()) {
                    await server.upsertRecognitionSkippedAsync(correlationId);
                }
            },
            onRecognitionActivityEnded: async correlationId => {
                const { shouldSaveAnswers } = this.state;
                if (shouldSaveAnswers()) {
                    await server.onRecognitionActivityEnded(correlationId);
                }
            },
            onOnboardingSurveyQuestionAnswered: async(
                correlationId,
                questionId,
                answer,
                score
            ) => {
                const {
                    setDisableNextButton,
                    _setCurrentStepState,
                    shouldSaveAnswers
                } = this.state;
                setDisableNextButton(true);
                _setCurrentStepState(step => ({
                    ...step,
                    answer,
                    score,
                    isAnswered: true
                }));

                if (shouldSaveAnswers()) {
                    await server.upsertOnboardingSurveyAnswerAsync(
                        correlationId,
                        questionId,
                        score
                    );
                }
            },
            onOnboardingSurveyQuestionSkipped: async(
                correlationId,
                questionId,
                isFollowUpQuestion
            ) => {
                const { _setCurrentStepState, shouldSaveAnswers } = this.state;
                _setCurrentStepState(step => ({
                    ...step,
                    answer: null,
                    score: null,
                    feedback: "",
                    isAnswered: false
                }));

                if (shouldSaveAnswers()) {
                    if (isFollowUpQuestion) {
                        await server.upsertOnboardingSurveyFollowUpAnswerSkippedAsync(
                            correlationId,
                            questionId
                        );
                    } else {
                        await server.upsertOnboardingSurveyAnswerSkippedAsync(
                            correlationId,
                            questionId
                        );
                    }
                }
            },
            onOnboardingSurveyFollowUpAnswerLeft: async(
                correlationId,
                questionId,
                feedback
            ) => {
                const {
                    setDisableNextButton,
                    _setCurrentStepState,
                    shouldSaveAnswers,
                    setIsAnswered
                } = this.state;
                setDisableNextButton(true);
                _setCurrentStepState(step => ({
                    ...step,
                    feedback
                }));

                if (shouldSaveAnswers()) {
                    if (feedback) {
                        setIsAnswered(true);
                        await server.upsertOnboardingSurveyFollowUpAnswerAsync(
                            correlationId,
                            questionId,
                            feedback
                        );
                    } else {
                        setIsAnswered(false);
                        await server.deleteOnboardingSurveyFollowUpAnswerAsync(
                            correlationId,
                            questionId
                        );
                    }
                }
            },
            onOnboardingSurveyActivityEnded: async correlationId => {
                const { shouldSaveAnswers } = this.state;
                if (shouldSaveAnswers()) {
                    await server.onOnboardingSurveyActivityEndedAsync(
                        correlationId
                    );
                }
            },
            onDeibSurveyQuestionAnswered: async(
                correlationId,
                questionId,
                answer,
                score
            ) => {
                const {
                    setDisableNextButton,
                    _setCurrentStepState,
                    shouldSaveAnswers
                } = this.state;
                setDisableNextButton(true);
                _setCurrentStepState(step => ({
                    ...step,
                    answer,
                    score,
                    isAnswered: true
                }));

                if (shouldSaveAnswers()) {
                    await server.upsertDeibSurveyAnswerAsync(
                        correlationId,
                        questionId,
                        score
                    );
                }
            },
            onDeibSurveyQuestionSkipped: async(correlationId, questionId) => {
                const { _setCurrentStepState, shouldSaveAnswers } = this.state;
                _setCurrentStepState(step => ({
                    ...step,
                    answer: null,
                    score: null,
                    isAnswered: false
                }));

                if (shouldSaveAnswers()) {
                    await server.upsertDeibSurveyAnswerSkippedAsync(
                        correlationId,
                        questionId
                    );
                }
            },
            onDeibSurveyActivityEnded: async correlationId => {
                const { shouldSaveAnswers } = this.state;
                if (shouldSaveAnswers()) {
                    await server.onDeibSurveyActivityEndedAsync(correlationId);
                }
            },
            onGoToPreviousStep: () => {
                const {
                    getCurrentStep,
                    getPreviousStep,
                    setDisableNextButton,
                    _setCurrentActivityState
                } = this.state;
                const step = getCurrentStep();

                if (step && !step.isFirstStep) {
                    setDisableNextButton(false);
                    const previousStep = getPreviousStep();
                    const previousQuestionBeforeFollowUpStep =
                        getPreviousStep(2);

                    if (
                        previousStep?.stepType === StepType.AskFollowUpQuestion &&
                        !previousQuestionBeforeFollowUpStep?.isAnswered
                    ) {
                        _setCurrentActivityState(currentActivity => ({
                            ...currentActivity,
                            currentStepIndex:
                                currentActivity.currentStepIndex - 2
                        }));
                    } else {
                        _setCurrentActivityState(currentActivity => ({
                            ...currentActivity,
                            currentStepIndex:
                                currentActivity.currentStepIndex - 1
                        }));
                    }
                }
            },
            onGoToNextStep: isSkip => {
                const {
                    getCurrentStep,
                    getNextStep,
                    getCurrentActivity,
                    _setCurrentActivityState
                } = this.state;
                const step = getCurrentStep();
                const activity = getCurrentActivity();

                if (step && !step.isLastStep) {
                    const nextStep = getNextStep();

                    const shouldSkipFollowUpQuestion =
                        isSkip &&
                        nextStep?.stepType === StepType.AskFollowUpQuestion &&
                        !step.isAnswered;
                    const shouldSkip1stQuestionOnContinueSurvey =
                        activity?.activityType ===
                            ActivityType.ContinuePulseSurvey &&
                        step.stepType === PulseSurveyStepType.Intro;
                    const shouldSkip1stQuestionOnContinueOnboardingSurvey =
                        activity?.activityType ===
                            ActivityType.ContinueOnboardingSurvey &&
                        step.stepType === OnboardingSurveyStepType.Intro;

                    if (
                        shouldSkipFollowUpQuestion ||
                        shouldSkip1stQuestionOnContinueSurvey ||
                        shouldSkip1stQuestionOnContinueOnboardingSurvey
                    ) {
                        _setCurrentActivityState(currentActivity => ({
                            ...currentActivity,
                            currentStepIndex:
                                currentActivity.currentStepIndex + 2
                        }));
                    } else {
                        _setCurrentActivityState(currentActivity => ({
                            ...currentActivity,
                            currentStepIndex:
                                currentActivity.currentStepIndex + 1
                        }));
                    }
                }
            },
            onGoToOutro: () => {
                const { activities } = this.state;
                const getOutroType = () => {
                    const lastActivity = activities?.[activities.length - 1];
                    const activityType = lastActivity?.activityType;
                    switch (activityType) {
                        case ActivityType.PulseSurvey:
                        case ActivityType.ContinuePulseSurvey:
                        case ActivityType.CustomPoll:
                        case ActivityType.SmartQuestion:
                        case ActivityType.OnboardingSurvey:
                        case ActivityType.ContinueOnboardingSurvey:
                        case ActivityType.DeibSurvey:
                            return OutroType.PulseSurveyRedirect;

                        case ActivityType.TryPulseSurvey:
                        case ActivityType.PreviewCustomPoll:
                        case ActivityType.TestCustomPoll:
                        case ActivityType.TestPulseSurveyQuestion:
                        case ActivityType.TestSmartQuestion:
                        case ActivityType.TestOnboardingSurvey:
                        case ActivityType.TestDeibSurvey:
                            return OutroType.PreviewCompleted;

                        case ActivityType.Recognition:
                        case ActivityType.TestRecognition:
                            if (lastActivity?.isAnswered) {
                                return lastActivity.recipientIds.length > 1 ? OutroType.MultipleRecipientsRecognitionSentRedirect : OutroType.RecognitionSentRedirect;
                            }

                            return OutroType.RecognitionSkippedRedirect;

                        default:
                            throw new Error(
                                `Activity Type (${activityType}) not supported`
                            );
                    }
                };

                this.setState({ isCompleted: true, outro: getOutroType() });
            },
            _setCurrentActivityState: fn => {
                this.setState(s => ({
                    ...s,
                    activities:
                        s.activities?.map((x, i) =>
                            i === s.orchestrator?.currentActivityIndex
                                ? fn(x)
                                : x
                        ) ?? null
                }));
            },
            _setCurrentStepState: fn => {
                const { _setCurrentActivityState } = this.state;
                _setCurrentActivityState(
                    currentActivity =>
                        ({
                            ...currentActivity,
                            ...("steps" in currentActivity
                                ? {
                                    steps: currentActivity.steps.map(
                                        (x, i) =>
                                            i ===
                                              currentActivity.currentStepIndex
                                                ? fn(x)
                                                : x
                                    )
                                }
                                : {})
                        }) as OrchestratedActivity
                );
            },
            getControlBarState: () => {
                const {
                    activities,
                    orchestrator,
                    isCompleted,
                    disableNextButton
                } = this.state;

                return getControlBarState(
                    activities,
                    orchestrator?.currentActivityIndex ?? null,
                    isCompleted,
                    disableNextButton
                );
            },
            getBannerState: () => {
                const { getCurrentActivity } = this.state;

                return getBannerState(getCurrentActivity());
            },
            getBackgroundState: isWorkleapBrandEnabled => {
                const { getCurrentActivity, isCompleted, outro } = this.state;

                return getBackgroundState(
                    getCurrentActivity(),
                    isCompleted,
                    outro,
                    isWorkleapBrandEnabled
                );
            },
            getCurrentTip: () => {
                const { getCurrentActivity } = this.state;

                return getCurrentTip(getCurrentActivity());
            }
        };
    }

    render() {
        const { children } = this.props;

        return (
            <ActivitiesContext.Provider value={this.state}>
                {children}
            </ActivitiesContext.Provider>
        );
    }
}

interface WrapperProps {
    children: React.ReactNode;
}

const ActivitiesContextProviderWrapper = ({ children }: WrapperProps) => {
    const server = useActivitiesServerSyncContext();

    return (
        <ActivitiesContextProvider server={server}>
            {children}
        </ActivitiesContextProvider>
    );
};

export default ActivitiesContextProviderWrapper;
